define(['app', '$console'], (app, $console) => {

  const brandsFacet = () => {
    const component = {};

    const _config = {
      selectors: {
        brandGroup: '.brandsFacet_group',
        brandGroupParent: '.brandsFacet_groups',
        brandInitial: '.brandsFacet_initial',
        results: '.brandsFacet_results',
        emptyResults: '.brandsFacet_noResults',
        searchInput: '.brandsFacet_searchInput',
        searchButton: '.brandsFacet_searchButton',
        facet: '.brandGroup_facet',
        facetValue: '.brandGroup_facetValue',
        groupKey: '.brandGroup_key',
        allInitialsButton: '.brandsFacet_allInitials',
        clearSearchButton: '.brandsFacet_clearSearch'
      },
      attrs: {
        enabled: 'data-enabled',
        active: 'data-active',
        clicked: 'data-clicked',
        ariaChecked: 'aria-checked'
      },
      channels: {
        close: 'brandsFacet/close',
        brandGroup: 'brandsFacet/group'
      }
    };

    const _init = (element) => {
      component.element = element;
      component.brandGroups = Array.from(component.element.querySelectorAll(component.config.selectors.brandGroup));
      component.brandGroupsParent = component.element.querySelector(component.config.selectors.brandGroupParent);
      component.brandInitials = Array.from(component.element.querySelectorAll(component.config.selectors.brandInitial));
      component.emptyResults = component.element.querySelector(component.config.selectors.emptyResults);
      component.searchInput = component.element.querySelector(component.config.selectors.searchInput);
      component.searchButton = component.element.querySelector(component.config.selectors.searchButton);
      component.allGroupFacets = Array.from(component.element.querySelectorAll(component.config.selectors.facet));
      component.allInitialsButton = component.element.querySelector(component.config.selectors.allInitialsButton);
      component.clearSearchButton = component.element.querySelector(component.config.selectors.clearSearchButton);

      component.subscribe();
      component.addEventListeners();
      return component;
    };

    const _subscribe = () => {
      app.subscribe(component.config.channels.close, component.clearSelections);
    };

    const _addEventListeners = () => {
      component.searchInput.addEventListener('input', component.searchBrands);
      component.searchButton.addEventListener('click', component.searchButtonClicked);
      component.allInitialsButton.addEventListener('click', component.allInitialsButtonClicked);
      component.clearSearchButton.addEventListener('click', component.clearButtonClicked);

      for (let brandInitial of component.brandInitials) {
        if (brandInitial.hasAttribute(component.config.attrs.enabled)) {
          brandInitial.addEventListener('click', component.brandInitialClicked.bind(null, brandInitial));
          brandInitial.addEventListener('keydown', component.triggerBrandClick.bind(this, brandInitial));
        }
      }
    };

    const _searchBrands = () => {
      component.resetStylingChanges();
      component.deselectCurrentInitial();
      let inputValue = component.cleanText(component.searchInput.value);
      if (inputValue) {
        let firstChar = inputValue.charAt(0);
        let brandGroup = component.findBrandGroup(firstChar);
        if (brandGroup) {
          brandGroup.setAttribute('data-active', '');
          component.allInitialsButton.removeAttribute(component.config.attrs.active);
          component.allInitialsButton.setAttribute(component.config.attrs.ariaChecked, 'false');
          component.setBrandInitial(firstChar);
          let facets = brandGroup.querySelectorAll(component.config.selectors.facet);
          let totalLength = facets.length;
          let index = 0;
          for (let facet of facets) {
            let facetValue = facet.querySelector(component.config.selectors.facetValue).innerText;
            if (!component.cleanText(facetValue).includes(inputValue)) {
              facet.classList.add('hide');
              index++;
            }
          }

          if (totalLength === index) {
            component.displayEmptyResults();
          } else {
            app.publish(component.config.channels.brandGroup, brandGroup);
            component.displayResults(brandGroup);
          }
        }
      } else {
        component.clearSelection();
      }
    };

    const _searchButtonClicked = () => {
      $console.log('Search button clicked');
    };

    const _brandInitialClicked = (selection) => {
      component.resetStylingChanges();
      let previousSelection = component.deselectCurrentInitial();
      if (selection) {
        let groupKey = selection.innerText;

        if (previousSelection !== groupKey) {
          component.allInitialsButton.removeAttribute(component.config.attrs.active);
          component.allInitialsButton.setAttribute(component.config.attrs.ariaChecked, 'false');
          selection.setAttribute(component.config.attrs.ariaChecked, 'true');
          selection.setAttribute(component.config.attrs.active, '');
          let brandGroup = component.findBrandGroup(groupKey);
          if (brandGroup) {
            brandGroup.setAttribute('data-active', '');
            app.publish(component.config.channels.brandGroup, brandGroup);
            component.displayResults(brandGroup);
          }
        }
      }
    };

    const _findBrandGroup = (brandInitialLetter) => {
      for (let brandGroup of component.brandGroups) {
        let groupKey = brandGroup.querySelector(component.config.selectors.groupKey).innerText.trim().replace(/\s+/g, ' ');
        if (groupKey === brandInitialLetter) {
          return brandGroup;
        }
      }
    };

    const _clearSelection = () => {

      app.publish(component.config.channels.brandGroup);
      if (component.brandGroupsParent.classList.contains('hide')) {
        component.brandGroupsParent.classList.remove('hide');
      }

      if (!component.emptyResults.classList.contains('hide')) {
        component.emptyResults.classList.add('hide');
      }
    };

    const _deselectCurrentInitial = () => {
      let currentSelection = Array.from(component.brandInitials)
        .filter(initial => {
          return initial.hasAttribute(component.config.attrs.active)
        })[0];
      if (currentSelection) {
        component.allInitialsButton.setAttribute(component.config.attrs.active, '');
        component.allInitialsButton.setAttribute(component.config.attrs.ariaChecked, 'true');
        app.publish(component.config.channels.brandGroup);
        currentSelection.setAttribute(component.config.attrs.ariaChecked, 'false');
        currentSelection.removeAttribute(component.config.attrs.active);
        component.clearSelection();
        return currentSelection.innerText;
      }
    };

    const _clearSearchField = () => {
      component.searchInput.value = '';
    };

    const _setBrandInitial = (brandInitialLetter) => {
      for (let brandInitial of component.brandInitials) {
        if (brandInitial.hasAttribute(component.config.attrs.enabled) && brandInitial.innerText === brandInitialLetter) {
          brandInitial.setAttribute(component.config.attrs.active, '');
          break;
        }
      }
    };

    const _cleanText = (text) => {
      return text.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    };

    const _publishBrandGroup = (allGroupFacets) => {
      app.publish('horizontalFacets/brandGroup', allGroupFacets);
    };

    const _displayResults = (brandGroup) => {
      for (let group of component.brandGroups) {
        if (group.getAttribute('data-index') !== brandGroup.getAttribute('data-index')) {
          group.classList.toggle('hide');
        }
      }
    };

    const _displayEmptyResults = () => {
      component.brandGroupsParent.classList.add('hide');
      component.emptyResults.classList.remove('hide');
    };

    const _clearSelections = () => {
      component.resetStylingChanges();
      component.clearSearchField();
      component.deselectCurrentInitial();
    };

    const _resetStylingChanges = () => {
      component.brandGroups.forEach(brandGroup => {
        brandGroup.classList.remove('hide');
        brandGroup.removeAttribute('data-active');
      });

      component.allGroupFacets.forEach(groupFacet => {
        groupFacet.classList.remove('hide');
      });
    };

    const _triggerBrandClick = (brandInitial, e) => {
      if (e.key === ' ') {
        e.preventDefault();
        component.brandInitialClicked(brandInitial)
      }
    };

    const _allInitialsButtonClicked = () => {
      let inactive = !component.allInitialsButton.hasAttribute(component.config.attrs.active);
      if (inactive) {
        component.allInitialsButton.setAttribute(component.config.attrs.active, '');
        component.allInitialsButton.setAttribute(component.config.attrs.ariaChecked, 'true');
        component.resetStylingChanges();
        component.deselectCurrentInitial();
      }
    };

    const _clearButtonClicked = () => {
      component.clearSearchField();
      component.resetStylingChanges();
      component.deselectCurrentInitial();
    };

    component.config = _config;
    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.searchBrands = _searchBrands;
    component.searchButtonClicked = _searchButtonClicked;
    component.brandInitialClicked = _brandInitialClicked;
    component.findBrandGroup = _findBrandGroup;
    component.clearSelection = _clearSelection;
    component.deselectCurrentInitial = _deselectCurrentInitial;
    component.clearSearchField = _clearSearchField;
    component.setBrandInitial = _setBrandInitial;
    component.cleanText = _cleanText;
    component.publishBrandGroup = _publishBrandGroup;
    component.displayResults = _displayResults;
    component.displayEmptyResults = _displayEmptyResults;
    component.subscribe = _subscribe;
    component.clearSelections = _clearSelections;
    component.resetStylingChanges = _resetStylingChanges;
    component.triggerBrandClick = _triggerBrandClick;
    component.allInitialsButtonClicked = _allInitialsButtonClicked;
    component.clearButtonClicked = _clearButtonClicked;

    return component;
  };

  return brandsFacet;
});
